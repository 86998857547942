@tailwind base;
@tailwind components;

@import "utilities/font-face.scss";
@import "utilities/rem.scss";
@import "utilities/transition.scss";
@import "components/alpinejs.scss";
@import "components/links.scss";
@import 'base';

@tailwind utilities;